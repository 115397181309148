import Section2 from 'components/home/Section2';
import Section1 from 'components/home/Section1';
import AppLayout from 'components/layout/AppLayout';
import Navbar from 'components/navbar/Navbar';
import api from '../src/api';
import FactcheckMarathonBanner from 'components/FactcheckMarathonBanner';
export async function getServerSideProps({ query }) {
	try {
		var x = new Date();
		x.setDate(1);
		x.setMonth(x.getMonth() - 1);
		const requestQuery = [];

		if (query && query.curated && query.curated == '1') {
			requestQuery.push('childFriendly=true');
		}

		console.log(requestQuery);

		let { data: factchecks } = await api.get(`/factchecks?${requestQuery.join('&')}`, {
			headers: {
				['x-api-key']: '303733e1-cf59-40c5-b264-40c786587db1'
			}
		});

		let { data: texts } = await api.get('/content?page=home', {
			headers: {
				['x-api-key']: '303733e1-cf59-40c5-b264-40c786587db1'
			}
		});

		return {
			props: {
				error: null,
				data: factchecks,
				content: texts.content
			}
		}
	} catch (err) {
		console.log(err)
		return {
			props: {
				error: err.data
			}
		}
	}

}

const IndexPage = (props) => {
	const data = props.data
	return <AppLayout>
		<div style={{ backgroundColor: '#f0f2ff', position: 'absolute', width: '100vw', height: 1100, zIndex: -1, clipPath: 'polygon(0% 56%, 12% 66%, 64% 0%, 100% 0%, 100% 84%, 0% 100%, 0% 100%)' }}></div>
		<Navbar />
		{/* <FactcheckMarathonBanner /> */}
		<Section1 content={props.content} data={data.slice(0, 3)} />
		<Section2 data={data.slice(5, 11)} />
	</AppLayout>;

};

export default IndexPage;