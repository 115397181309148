import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import { useRouter } from 'next/router';

const TrapezoidButton = ({ children, backgroundColor, height, width }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  let _width = width || (isMobile ? 320 : 550)


  return (
    <div
      style={{
        backgroundColor: backgroundColor || '#171554',
        width: `${_width}px`,
        height: height || '65px',
        cursor: 'pointer',
        clipPath: 'polygon(50% 0%, 100% 3%, 97% 99%, 3% 100%, 0 0)',
        display: 'flex',
        justifyContent: 'center',
        transition: '0.3s ease-out',
        borderRadius: '0px',
      }}
    >
      {children}
    </div>
  );
};

export default TrapezoidButton;
