import CategorySectionHeader from 'components/CategorySectionHeader';
import { Container, Grid, styled } from '@mui/material';
import React from 'react';
import ArticleCard2 from 'components/articles/article-cards/ArticleCard2';
import Alert from '@mui/material/Alert';

import { useTheme } from '@emotion/react';
import { ArrowForward } from '@mui/icons-material';

import { useMediaQuery } from '@mui/material';
import TrapezoidButton from 'components/buttons/Trapezoid';
import Link from 'next/link';


const StyledColoredLink = styled(Link)(({
	theme
}) => ({
	position: 'relative',
	display: 'block',
	padding: '0.3rem 0rem',
	color: 'black',
	cursor: 'pointer',
	color: '#fd5b72',
	fontWeight: 800,
	fontsize: 20,
	borderRadius: 4,
}));

const StyledHeader = styled('h1')(({
	theme
}) => ({
	color: theme.palette.primary[100],
	fontSize: '40px',
	paddingTop: '70px'
}));

const Section2 = ({ data, error }) => {
	const [open, setOpen] = React.useState(true);
	const theme = useTheme();

	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	if (!data) {
		const handleClose = () => {
			setOpen(false);
		};

		return (
			<Container sx={{
				mb: '4rem'
			}}>
				<CategorySectionHeader title="" />
				{
					error && <Alert sx={{ marginBottom: 2 }} severity="error" onClose={handleClose}>Oeps, er ging iets mis bij het ophalen van artikels.</Alert>
				}
				<Grid container spacing={3}>
					<Grid item md={3} xs={12} key={3}>
						<ArticleCard2 loading={true} article={null} />
					</Grid>
					<Grid item md={3} xs={12} key={4}>
						<ArticleCard2 loading={true} article={null} />
					</Grid>
					<Grid item md={3} xs={12} key={5}>
						<ArticleCard2 loading={true} colored={true} article={null} />
					</Grid>
					<Grid item md={3} xs={12} key={6}>
						<ArticleCard2 loading={true} article={null} />
					</Grid>
				</Grid>
			</Container>
		);
	}

	return (
		<Container sx={{
			mb: '4rem',
		}}>
			<StyledHeader mt={20}>Meer factchecks</StyledHeader>
			<Grid container spacing={2} justifyContent="center">
				{data.map((article, index) => <Grid item md={4} xs={12} key={index} sx={{ justifyContent: 'center' }}>
					<ArticleCard2 article={article} />
				</Grid>)}
			</Grid>
			<Grid
				mt={3}
				container
				spacing={0}
				direction="column"
				alignItems="center"
				justifyContent="center"
			>
				<Grid item xs={3}>
					<Grid item xs={12}>
						<StyledColoredLink style={{ fontSize: 18, fontWeight: 600, textDecoration: 'underline', display: 'flex', alignItems: 'center', marginTop: 30 }} href={`/factchecks`}>
							Alle checks <ArrowForward style={{ marginLeft: 3, height: 19 }}></ArrowForward>
						</StyledColoredLink>
					</Grid>
				</Grid>
			</Grid>

			<Grid
				mt={4}
				container
				spacing={0}
				direction="column"
				alignItems="center"
				justifyContent="center"
			>

				<Grid item xs={3}>
					<Link href='/donatie'>
						<TrapezoidButton>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div style={{ flex: 1 }}>
										<div style={{ color: 'white', fontSize: isMobile ? 12 : 19, fontWeight: 600 }}>Steun deCheckers met een donatie</div>
									</div>
									<div>
										<ArrowForward sx={{ color: '#fd5b72', marginLeft: 1, marginTop: 1 }} />
									</div>
								</div>
							</div>
						</TrapezoidButton>
					</Link>
				</Grid>

			</Grid>
		</Container>
	);

};


export default Section2;
