import { Container, Grid, Paper, useMediaQuery } from '@mui/material';
import Image from 'next/image';
import React from 'react';
import TrapezoidButton from 'components/buttons/Trapezoid';
import { ArrowForward } from '@mui/icons-material';
import { useTheme } from '@emotion/react';
import NextLink from 'next/link';

const FactcheckMarathonBanner = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return <>
        <NextLink href='/factchecks?category=elections-2024'>
            <Container sx={{
                mt: 5,
            }}>
                <Paper sx={{ backgroundColor: '#28d797', width: '100%', }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={8} >
                            <h1 style={{ marginLeft: 20, color: 'white', fontSize: 16, textAlign: 'left', fontWeight: 400, marginRight: 20 }}>
                                Wij bundelen voor jou alle <span style={{ fontWeight: 800 }}>factchecks</span> rond de <span style={{ fontWeight: 800 }}>regionale,<br></br> federale en Europese verkiezingen.</span> Van 27 mei tot en met 9 juni<br></br> nemen we elke dag politieke uitspraken onder de loep.
                            </h1>
                            <div style={{ marginLeft: 20, marginBottom: 38 }}>
                                <TrapezoidButton width={260} height={30} >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{ flex: 1 }}>
                                                <div style={{ color: 'white', fontSize: isMobile ? 12 : 17, fontWeight: 600 }}>Lees de factchecks</div>
                                            </div>
                                            <div>
                                                <ArrowForward sx={{ color: '#fd5b72', marginLeft: 1, marginTop: 1 }} />
                                            </div>
                                        </div>
                                    </div>
                                </TrapezoidButton>
                            </div>

                        </Grid>
                        <Grid item xs={12} md={4} style={{ paddingTop: 0 }}>
                            <Image src="/assets/images/marathon.svg" alt="Factcheck Marathon" width={400} height={150} />
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </NextLink>
    </>
};

export default FactcheckMarathonBanner;